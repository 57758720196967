import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Importa los estilos de Bootstrap

export default function HubspotModalButton({ formId, portalId, buttonText }) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setLoading(true);
    };

    useEffect(() => {
        if (show) {
            // Cargar el script de HubSpot Forms solo cuando el modal esté abierto
            const script = document.createElement('script');
            script.src = 'https://js.hsforms.net/forms/embed/v2.js';
            script.defer = true;

            script.onload = () => {
                if (window.hbspt) {
                    window.hbspt.forms.create({
                        region: 'na1',
                        portalId: portalId,
                        formId: formId,
                        target: '#hubspotFormContainer',
                    });
                    setLoading(false); // Ocultar el indicador de carga una vez que el formulario esté listo
                }
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [show]);

    return (
        <>
            {/* Botón para abrir el modal */}
            <button
                type="button"
                className="typeformButton general green sm"
                onClick={handleShow}
            >
                {buttonText}
            </button>

            {/* Modal de Bootstrap */}
            <div
                className={`modal fade ${show ? "show" : ""}`}
                style={{ display: show ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="hubspotModalLabel"
                aria-hidden={!show}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {/* Encabezado del modal */}
                        <div className="modal-header">
                            <h5 className="modal-title" id="hubspotModalLabel">
                                Formulario de Contacto
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handleClose}
                            ></button>
                        </div>

                        {/* Cuerpo del modal */}
                        <div className="modal-body" style={{ minHeight: "500px" }}>
                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </div>
                                </div>
                            ) : (
                                <div id="hubspotFormContainer" style={{ width: "100%", height: "100%" }}></div>
                            )}
                        </div>

                        {/* Pie del modal */}
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleClose}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Fondo oscuro del modal */}
            {show && (
                <div
                    className="modal-backdrop fade show"
                    style={{ display: "block" }}
                ></div>
            )}
        </>
    );
}