import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { hideOffcanvas } from "../../Utilities/BootstrapApi";
import { isHomeUrl } from "../../Utilities/IsHomeUrl";

import LogoBlanco from "../../Assets/Logos/logo_blanco_usound.png";
import LogoColor from '../../Assets/Logos/logo_usound_color.svg'

/**
 * 
 * @description Menú de navegación dentro del Header
 */
export default function NavBar() {
    const navigate = useNavigate();
    const { pathname: path } = useLocation()

    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        let langBtn = document.querySelectorAll('.langButtons')
        for (let i = 0; i < langBtn.length; i++) {
            langBtn[i].classList.remove('currentLang')
            document.getElementById(i18n.language + 'Button').classList.add('currentLang')
        }
    }, [i18n.changeLanguage()])

    useEffect(() => {
        if (path === '/') {
            navigate(`/${i18n.language}`)
        }
    }, [path])

    return (
        <nav className="d-flex flex-wrap px-5">
            <div
                className="col-12 col-lg-8 text-center text-lg-start"
                onClick={() => {
                    navigate("/" + i18n.language)
                    hideOffcanvas('hambMenu')
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }}
            >
                {isHomeUrl(path) ?
                    <img
                        className="headerLogos cursor-pointer"
                        src={LogoBlanco}
                        alt="Logo uSound Audiómetro"
                    /> :
                    <img
                        className="headerLogos cursor-pointer"
                        src={LogoColor}
                        alt="Logo uSound Audiómetro"
                    />
                }
            </div>
            <ul className="d-flex flex-column flex-lg-row justify-content-center align-items-center list-style-none col-12 p-0 m-0 mt-3 mt-lg-0 col-lg-4">
                <li
                    className="cursor-pointer mx-2 sectionsLinks"
                    onClick={() => {
                        if (i18n.language === 'es') navigate("/es/profesionales")
                        if (i18n.language === 'pt') navigate("/pt/profissionais")
                        if (i18n.language === 'en') navigate("/en/professionals")
                        hideOffcanvas('hambMenu')
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }}
                >
                    {t("header.professionals")}
                </li>
                <li
                    className="cursor-pointer mx-2 me-lg-3 sectionsLinks"
                    onClick={() => {
                        if (i18n.language === 'es') navigate("/es/nuestro-equipo")
                        if (i18n.language === 'pt') navigate("/pt/nos")
                        if (i18n.language === 'en') navigate("/en/about-us")
                        hideOffcanvas('hambMenu')
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }}
                >
                    {t("header.about_us")}
                </li>
                <li
                    className="cursor-pointer mx-2 me-lg-3 sectionsLinks"
                    onClick={() => {
                        if (i18n.language === 'es') window.location.href = "https://support.usound.co/";
                        if (i18n.language === 'pt') window.location.href = "https://support.usound.co/";
                        if (i18n.language === 'en') window.location.href = "https://support.usound.co/";
                        hideOffcanvas('hambMenu')
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }}
                >
                    {t("header.support")}
                </li>
                <li className="sectionsLinks">
                    <span
                        id="esButton"
                        className="langButtons p-2 p-lg-1"
                        onClick={() => {
                            i18n.changeLanguage("es");
                            let split = path.split("/")[2]
                            console.log(split, i18n.language)
                            if (split === "nuestro-equipo") navigate("/es/nuestro-equipo")
                            if (split === "profesionales") navigate("/es/profesionales")
                            if (split === undefined) navigate("/es")
                        }}
                    >
                        ES
                    </span>
                    <span
                        id="ptButton"
                        className="langButtons mx-3 mx-lg-1 p-2 p-lg-1"
                        onClick={() => {
                            i18n.changeLanguage("pt");
                            let split = path.split("/")[2]
                            if (split === "nos") navigate("/pt/nos")
                            if (split === "profissionais") navigate("/pt/profissionais")
                            if (split === undefined) navigate("/pt")
                        }}
                    >
                        PT
                    </span>
                    <span
                        id="enButton"
                        className="langButtons p-2 p-lg-1"
                        onClick={() => {
                            i18n.changeLanguage("en");
                            let split = path.split("/")[2]
                            if (split === "about-us") navigate("/en/about-us")
                            if (split === "professionals") navigate("/en/professionals")
                            if (split === undefined) navigate("/en")
                        }}
                    >
                        EN
                    </span>
                </li>
            </ul>
        </nav >
    )
}