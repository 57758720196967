import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

export default function Contacto() {

    const [t] = useTranslation('global')

    useEffect(() => {
        // Cargar el script de HubSpot
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        script.charset = 'utf-8';
        script.type = 'text/javascript';
        document.body.appendChild(script);
    
        script.onload = () => {
          // Crear el formulario de HubSpot
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: '48116756',
              formId: '4f692286-6a77-4683-8698-4d282020af1b',
              target: '#hubspot-form',
              region: 'na1'
            });
          }
        };
    
        // Limpiar el script cuando el componente se desmonte
        return () => {
          document.body.removeChild(script);
        };
      }, []);

    return (
        <section id="contacto" className="pt-5 pb-5">

            <h3 className="text-center text-white">{t('home.contacto.title')}</h3>
            <p className="text-center text-secondary">
                {t('home.contacto.subtitle')}
            </p>

            <div className="p-2 mx-3 mx-lg-auto col-lg-10 text-center text-white form_header">
                {t('home.contacto.disclaimer')}
            </div>

            <article id="formContainer" className="border border-white p-5 mx-3 mx-lg-auto col-lg-6 mb-4">
                {/* El formulario de HubSpot se cargará aquí */}
                <div id="hubspot-form"></div>
            </article>

        </section>
    )
}


